import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { defaultDropdownSelect } from '../../../core/components/form/OEDropdown';
import { OEInputType } from '../../../core/components/form/OEInput';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEForm from '../../../core/components/formik/OEForm';
import OEFormBoolean from '../../../core/components/formik/OEFormBoolean';
import OEFormDropdown from '../../../core/components/formik/OEFormDropdown';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import OEHeading from '../../../core/components/general/OEHeading';
import OEModal, { ModalSize, OEModalBody } from '../../../core/components/general/OEModal';
import { getReportFilterList, IReportBaseFilter, IReportFilter } from '../../../report-microstrategy/entities/ReportFilter';
import { useGetEmbeddedReportFilters } from '../../services/ReportFilterService';

const labelColumns: number = 3;

interface IFormInfo {
    lookupTables: IReportFilter[];
    isEditing: boolean;
}

const Form: React.FunctionComponent<FormikProps<IReportBaseFilter> & IFormInfo> = ({ values, errors, touched, setFieldValue, isEditing, lookupTables }) => {

    const setFilterLabel = (i: any) => {
        if (i !== '') {
            const s: IReportFilter = lookupTables.filter(q => q.name === i)[0];
            setTimeout(function () {
                setFieldValue('name', s.name);
                setFieldValue('label', s.label);
                setFieldValue('externalId', s.externalId);
            }, 100)
        }
    }

    return (
        <>
            {!values.dossier && (
                <OEFormDropdown
                    label="Filter" name="name" value={values.name}
                    fields={{ id: 'name', name: 'label' }} disabled={isEditing}
                    onChange={setFilterLabel} alwaysShowDefault={false} defaultSelect={defaultDropdownSelect}
                    errors={errors} touched={touched} columns={labelColumns} values={lookupTables}
                />
            )}
            {values.dossier && (
                <OEFormInput
                    label="Name" name="name" value={values.name} required={true}
                    errors={errors} touched={touched} columns={labelColumns}
                    setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
                />
            )}
            <OEFormInput
                label="Label" name="label" value={values.label} required={true}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
            />
            {values.dossier && (
                <>
                    <OEFormInput
                        label="Select All Text" name="selectAllText" value={values.selectAllText}
                        errors={errors} touched={touched} columns={labelColumns}
                        instructions='Select All Text Override'
                        setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
                    />

                    <OEFormInput
                        label="No Filter" name="noFilterText" value={values.noFilterText}
                        errors={errors} touched={touched} columns={labelColumns}
                        instructions='Label for selecting no filters'
                        setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
                    />

                    <OEFormInput
                        label="Default Selection" name="defaultSelection" value={values.defaultSelection}
                        errors={errors} touched={touched} columns={labelColumns}
                        instructions='Default Value for Filter'
                        setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
                    />

                    <OEFormBoolean
                        label="Ignore Filter" name="ignoreFilter" value={values.ignoreFilter}
                        errors={errors} touched={touched} columns={labelColumns}
                        setFieldValue={setFieldValue}
                    />

                    <OEFormInput
                        label="Cascade Priority" name="cascadePriority" value={values.cascadePriority}
                        errors={errors} touched={touched} columns={labelColumns}
                        instructions='Priority for cascading (lower number cascade first)'
                        setFieldValue={setFieldValue} inputType={FormInputType.Number} type={OEInputType.Number}
                    />

                    <OEFormInput
                        label="Cube Id" name="cubeId" value={values.cubeId}
                        errors={errors} touched={touched} columns={labelColumns}
                        instructions='Ovveride for Report Cube Id'
                        setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
                    />

                    <OEFormBoolean
                        label="Multiple Select" name="mulitipleSelect" value={values.mulitipleSelect}
                        errors={errors} touched={touched} columns={labelColumns}
                        setFieldValue={setFieldValue}
                    />

                    <OEFormInput
                        label="Sort Index" name="rawValueSortIndex" value={values.rawValueSortIndex}
                        errors={errors} touched={touched} columns={labelColumns}
                        instructions='The index from the cube raw values for the filter will be used for sorting'
                        setFieldValue={setFieldValue} inputType={FormInputType.Number} type={OEInputType.Number}
                    />
                </>
            )}
            {!values.dossier && (
                <>
                    <OEFormBoolean
                        label="Select All" name="showSelectAll" value={values.showSelectAll}
                        errors={errors} touched={touched} columns={labelColumns}
                        setFieldValue={setFieldValue}
                    />
                    <OEFormInput
                        label="Override Value" name="overrideValue" value={values.overrideValue}
                        errors={errors} touched={touched} columns={labelColumns}
                        instructions='Override Value to use for Select All'
                        setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
                    />
                    <OEFormInput
                        label="Range Increments" name="rangeIncrements" value={values.rangeIncrements}
                        errors={errors} touched={touched} columns={labelColumns}
                        instructions='For range filters how many increments to go back to'
                        setFieldValue={setFieldValue} inputType={FormInputType.Number} type={OEInputType.Number}
                    />

                </>
            )}
            <OEFormInput
                label="MS Filter Name" name="msId" value={values.msId} required={false}
                errors={errors} touched={touched} columns={labelColumns}
                instructions='Enter name of ms filter here to override portal filters'
                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
            />
        </>
    );
};

const ValidationScheme = Yup.object<IReportBaseFilter>().shape({
    name: Yup.string().required('Filter is required').nullable(),
    label: Yup.string().required('Label is required').nullable(),
});

interface IFormikInfo {
    item: IReportBaseFilter;
    isEditing: boolean;
    onCancel: () => void;
    onSubmit: (i: IReportBaseFilter) => void;
}

const ReportFilterFormik: React.FunctionComponent<IFormikInfo> = ({ item, isEditing, onCancel, onSubmit }) => {

    const { service: filterService } = useGetEmbeddedReportFilters(true);

    const [embeddedFilters, setEmbeddedFilters] = useState<IReportFilter[]>([]);
    const [localValues] = useState(item);

    useEffect(() => {
        if (filterService.result) {
            setEmbeddedFilters(getReportFilterList(filterService.result));
        }
    }, [filterService]);

    const getForm = (props: FormikProps<any>, p: boolean) => (
        <OEForm {...props}
            onCancel={onCancel}
            component={Form}
            submitText="Submit"
            cancelText="Cancel"
            lookupTables={embeddedFilters}
            labelColumns={2}
            isEditing={isEditing}
        />
    );

    return (
        <OEModal show={true} oeSize={ModalSize.Medium} onHide={onCancel}>
            <OEModalBody className="alert-light">
                <OEHeading size={3} text={`${isEditing ? 'Edit' : 'Add'} Report ${localValues.dossier ? 'Dossier' : ''} Filter`} />
                <Formik
                    onSubmit={onSubmit}
                    initialValues={localValues}
                    enableReinitialize={true}
                    validationSchema={ValidationScheme}
                >
                    {(props) => getForm(props, isEditing)}
                </Formik>

            </OEModalBody>
        </OEModal >
    );
};

export default ReportFilterFormik;
