import React, { Fragment, useEffect, useRef, useState } from 'react';
import OEMessage from '../../../core/components/messaging/OEMessage';
import OENotification from '../../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../core/components/messaging/enums/InformationMessages';
import { displayHTMLContent } from '../../../core/utilities/Miscellaneous';
import ReportSectionLabel from '../../../reporting/components/admin-reports/ReportSectionLabel';
import { canDebugReport } from '../../../reporting/entities/Report';
import { ReportConfigurationType, defaultReportConfigurations, getReportConfigurationValue } from '../../../reporting/entities/ReportConfiguration';
import { IReportGroup, ReportGroupType, defaultReportGroup } from '../../../reporting/entities/ReportGroup';
import { IReportPage, defaultReportPage } from '../../../reporting/entities/ReportPage';
import { ProcessingPage, logProcessingPage, updateProcessingStep } from '../../../reporting/entities/ReportProcessing';
import { ReportType } from '../../../reporting/entities/ReportType';
import { ISiteReport, defaultSiteReport } from '../../../reporting/entities/SiteReport';
import { useGetEmbeddedReportFilters } from '../../../reporting/services/ReportFilterService';
import { useGetReportPagePublic } from '../../../reporting/services/ReportPageService';
import { useGetSiteReport, useGetSiteReportFilters } from '../../../reporting/services/SiteReportService';
import { IDossierSettings, defaultDossierSettings } from '../../entities/MicroStrategyDossier';
import { IMSFilter, defaultMSFilter, getMSFilters, getReportFilters } from '../../entities/MicroStrategyFilters';
import { IReportFilter, getFiltersFromBaseFilters, getJSONFromReportFilter, getReportFilterList } from '../../entities/ReportFilter';
import Filters from '../report-common/Filters';
import Report from '../report-common/Report';
import SectionGroup from './SectionGroup';
import SectionItem from './SectionItem';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

interface IComponentInfo {
    pageId: string;
}

const MSEmbeddedPublic: React.FunctionComponent<IComponentInfo> = ({ pageId }) => {
    const [debug] = useState<boolean>(canDebugReport());
    const headerRef = useRef<any>();
    const footerRef = useRef<any>();
    const reportRef = useRef<any>();

    const { service: embeddedFilterService, doRefresh: loadFilters } = useGetEmbeddedReportFilters(false); // Step 1 - get base filters for all objects
    const { service: pageService, setPageId: loadPage } = useGetReportPagePublic(''); // Step 4 - get the page information
    const { service: siteReportService, setId: setSiteReportId } = useGetSiteReport(''); // Step 5 - get the site report inormation
    const { service: siteReportFilterService, setReport: setSiteReportFilter } = useGetSiteReportFilters(); // Step 6 - get filters for page

    const [baseFilters, setBaseFilters] = useState<IReportFilter[]>([]);
    const [pageFilters, setPageFilters] = useState<IReportFilter[]>([]);
    const [siteFilters, setSiteFilters] = useState<IReportFilter[]>([]);
    const [sectionFilters, setSectionFilters] = useState<IReportFilter[]>([]);

    const [processingStep, setProcessingStep] = useState<ProcessingPage>(ProcessingPage.NotDefined);

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageSettings, setPageSettings] = useState<IDossierSettings>(defaultDossierSettings);
    const [section, setSection] = useState<IReportGroup>(defaultReportGroup);
    const [group, setGroup] = useState<IReportGroup>(defaultReportGroup);
    const [siteReport, setSiteReport] = useState<ISiteReport>(defaultSiteReport);
    const [notiication, setNotification] = useState<INotification>(defaultNotification);
    const [filters, setFilters] = useState<IMSFilter[]>([]);
    const [filterReport, setFilterReport] = useState<boolean>(false);
    const [hideApply, setHideApply] = useState<boolean>(false);

    const [filterInitialzied, setFilterInitialzied] = useState<boolean>(false);
    const [footer, setFooter] = useState<string>('');
    const [reportHeight, setReportHeight] = useState<number>(0);
    const [navHeight, setNavHeight] = useState<number>(0);

    useEffect(() => {
        window.onresize = windowResized;
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        logProcessingPage(processingStep, debug);
        switch (processingStep) {
            case ProcessingPage.LoadMenu:
                loadFilters();
                break;

            case ProcessingPage.PageLoad:
                loadPage(pageId);
                break;

            case ProcessingPage.PageLoaded:
                updateProcessingStep(ProcessingPage.SectionLoad, setProcessingStep);
                break;

            case ProcessingPage.GroupLoadInitial:
                loadGroup();
                break;

            case ProcessingPage.GroupLoaded:
                updateProcessingStep(ProcessingPage.ReportLoad, setProcessingStep);

                break;

            case ProcessingPage.SectionLoad:
                setSection(page.groups[0].groups[0]);
                break;

            case ProcessingPage.SectionLoaded:
                initFilters();
                break;

            case ProcessingPage.Finished:
                updateHeight();
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);


    useEffect(() => {
        if (embeddedFilterService.result) {
            setBaseFilters(getReportFilterList(embeddedFilterService.result));
            updateProcessingStep(ProcessingPage.PageLoad, setProcessingStep);
        }
        // eslint-disable-next-line
    }, [embeddedFilterService]);

    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        if (siteReportService.result) {
            setSiteReport(siteReportService.result);
        }
        // eslint-disable-next-line
    }, [siteReportService]);

    useEffect(() => {
        if (siteReport.id !== '') {
            const reportFilters: IReportFilter[] = [...pageFilters, ...sectionFilters];
            for (const i of reportFilters) {
                if (filters.filter(q => q.name === i.name).length > 0) {
                    i.value = filters.filter(q => q.name === i.name)[0].value;
                }
            }
            setSiteReportFilter({ ...siteReport, pageId: section.id, reportFilters });
        }
        // eslint-disable-next-line
    }, [siteReport]);

    useEffect(() => {
        if (pageId !== '') {
            loadFilters();
        }
        // eslint-disable-next-line
    }, [pageId]);

    useEffect(() => {
        if (page.id !== '') {
            updateProcessingStep(ProcessingPage.PageLoaded, setProcessingStep);
        }
        // eslint-disable-next-line
    }, [page]);


    useEffect(() => {
        if (section.id !== '') {
            //            updateProcessingStep(ReportProcessingStep.Finished ? ReportProcessingStep.LoadSection : ReportProcessingStep.SectionLoaded, setProcessingStep);
            updateProcessingStep(ProcessingPage.SectionLoaded, setProcessingStep);
        }
        // eslint-disable-next-line
    }, [section]);

    useEffect(() => {
        if (group.id !== '') {
            setProcessingStep(ProcessingPage.GroupLoadInitial);
        }
        // eslint-disable-next-line
    }, [group]);

    useEffect(() => {
        if (siteReportFilterService.isFinished) {
            setSiteFilters(siteReportFilterService.data.data);
            // setFilterValues(siteReportFilterService.data.data);
        }
        // eslint-disable-next-line
    }, [siteReportFilterService]);

    useEffect(() => {
        if (siteFilters.length > 0) {
            setFilterInitialzied(true);
            filterInitialzied ? updateFilters() : initFilters();
        }
        // eslint-disable-next-line
    }, [siteFilters]);

    useEffect(() => {
        if (group.id === '' && filters.length > 0) {
            section.groups.length > 0 ? setGroup(section.groups[0]) : setGroup(defaultReportGroup);
        }
        filterInitialzied && window.setTimeout(() => {
            updateProcessingStep(ProcessingPage.LoadDossierFilters, setProcessingStep);
        }, 2000); // this delay is necessary to refresh cascading filters 
        // eslint-disable-next-line
    }, [filters]);

    const windowResized = () => {
        updateHeight();
    }

    const loadGroup = () => {

        let nf: IMSFilter[] = filters.filter(q => q.level < 3);
        if (group.reports.length > 0) {
            debug && console.log(getJSONFromReportFilter(group.reports[0].filters).filter(q => q.dossier));
            for (const i of getJSONFromReportFilter(group.reports[0].filters).filter(q => q.dossier)) {
                nf.push({ ...defaultMSFilter, selectAllText: i.selectAllText, showSelectAll: i.showSelectAll, name: i.name, label: i.label, level: 3, dossier: true });
            }
            debug && console.log('selected report filters', group.reports[0].filters);
            debug && console.log('new filters', nf);
            setFooter(getReportConfigurationValue(ReportConfigurationType.ReportFoooter, group.configuration));
        }
        setFilters(nf);
        setPageSettings({
            ...defaultDossierSettings, hideFilterSummary:
                getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, page.configuration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, section.configuration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, group.configuration)
        });
        setProcessingStep(ProcessingPage.GroupLoaded);
    }

    const updateHeight = () => {
        if (headerRef.current) {
            const h: number =
                headerRef.current.getBoundingClientRect().height
                + (footerRef.current ? footerRef.current.getBoundingClientRect().height : 16)
                + headerRef.current.offsetTop + 55;

            setReportHeight(h);
            setNavHeight(headerRef.current.offsetTop + 20);
            //    setNotification({ duration: 10000, message: `report top ${headerRef.current.getBoundingClientRect().height}, 
            //    footer ${footerRef.current ? footerRef.current.getBoundingClientRect().height : 0}, header ${headerRef.current.offsetTop}, totalHeight ${h}`, type: 'info' });
        }
    }

    const initFilters = () => {
        setPageFilters(getFiltersFromBaseFilters(page.filters, baseFilters));
        setSectionFilters(getFiltersFromBaseFilters(section.filters, baseFilters));
        const f: IMSFilter[] = getMSFilters(page.filters, siteFilters, baseFilters, 1);
        for (const i of getMSFilters(section.filters, siteFilters, baseFilters, 2)) {
            if (f.filter(q => q.name === i.name).length === 0) {
                f.push(i);
            }
        }
        setFilters(f);
        if (filters.length > 0) {
            section.groups.length > 0 ? setGroup(section.groups[0]) : setGroup(defaultReportGroup);
        }
        setSiteReportId(ReportType.EmbeddedFilters);
        setHideApply(getReportConfigurationValue(ReportConfigurationType.HideApply, page.configuration));
        debug && setNotification({ message: 'Setting Filters', type: 'info' });
        updateProcessingStep(ProcessingPage.GroupLoadInitial, setProcessingStep);
        //setFilterReport(true);
    }

    const updateFilters = () => {
        const f: IMSFilter[] = getMSFilters(page.filters, siteFilters, baseFilters, 1);
        for (const i of getMSFilters(section.filters, siteFilters, baseFilters, 2)) {
            if (f.filter(q => q.name === i.name).length === 0) {
                f.push(i);
            }
        }
        for (const i of f.filter(q => !q.dossier)) {
            i.value = filters.filter(q => i.name === q.name).length > 0 ? filters.filter(q => i.name === q.name)[0].value : '';
        }
        const v: IMSFilter[] = [];
        for (const c of filters) {
            if (f.filter(q => q.name === c.name).length > 0) {
                v.push(f.filter(q => q.name === c.name)[0]);
            }
            else {
                v.push(c);
            }
        }
        setFilters(v);
    }

    const updateFilterValues = (f: IMSFilter[], level: number, isCascading: boolean, dossier: boolean) => {
        if (!dossier && isCascading) {
            debug && setNotification({ message: `Filters changed ${dossier} - ${level}`, type: 'info' });
            if (level === 1) {
                setSiteReport({ ...siteReport, reportFilters: getReportFilters([...f, ...filters.filter(q => q.level === 2)], siteReport) });
            }
            else {
                setSiteReport({ ...siteReport, reportFilters: getReportFilters([...filters.filter(q => q.level === 1), ...f,], siteReport) });
            }
        }
        if (processingStep === ProcessingPage.Finished) {
            updateProcessingStep(ProcessingPage.LoadDossierFilters, setProcessingStep);
        }
    }

    const onChangeSection = (i: IReportGroup) => {
        setReportHeight(0);
        setSection(i);
    }

    const onChangeGroup = (i: IReportGroup) => {
        setReportHeight(0);
        setGroup(i);
    }

    const onFilterReport = () => {
        setFilterReport(true);
    }

    return (
        <div className="report-embedded m-b-0">
            <OENotification setNotification={setNotification} notification={notiication} />
            {page.id !== '' && (
                <>
                    <p className="title m-t-10 m-b-5 m-l-10"> </p>
                    <div className="three-tier">
                        <div style={{ minHeight: `calc(100vh - ${navHeight}px)` }} className="left-nav">
                            {page.groups.filter(q => q.isActive).map((item, index) =>
                                <Fragment key={index} >
                                    <div className="section-header">
                                        <ReportSectionLabel group={item} />
                                    </div>
                                    {item.groups.filter(q => q.isActive).map((item2, index2) =>
                                        <div key={index2} className={`section-item ${item2.id === section.id ? 'active' : ''} `} >
                                            <SectionItem onClick={onChangeSection} group={item2} />
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </div>
                        <div className="content">
                            <div ref={headerRef} >
                                {filters.filter(q => q.level === 1).length > 0 && (
                                    <Filters
                                        updateFilterValues={updateFilterValues}
                                        level={1}
                                        hideApply={hideApply}
                                        filterReport={onFilterReport}
                                        filters={filters.filter(q => !q.dossier)}
                                        setFilters={setFilters}
                                    />
                                )}
                                {section.groups.length > 1 && (
                                    <div className="groups">
                                        {section.groups.filter(q => q.isActive).map((item3, index3) =>
                                            <SectionGroup onClick={onChangeGroup} className={`${item3.id === group.id ? 'active' : ''}`} key={index3} group={item3} />
                                        )}
                                    </div>
                                )}
                                <p className="subtitle m-t-10 m-l-10">{`${section.title}`} </p>
                                {section.description && <p className="description m-t-10 m-l-10">{`${section.description}`} </p>}

                            </div>
                            <div ref={reportRef} className="report">
                                {group.id !== '' && (
                                    <>
                                        {group.reports.length === 0 && group.groupType !== ReportGroupType.AboutPage && (
                                            <OEMessage className="h5"
                                                type={MessageType.Secondary}
                                                hideDismissable={true}
                                                message={`There are no reports setup for the report group: <b><i> ${group.title} </b></i> in the section <b><i> ${section.title}</b></i>`} />
                                        )}
                                        {group.groupType === ReportGroupType.Report && group.reports.filter(q => q.isActive).map((item4, index4) =>
                                            <Report
                                                processingStep={processingStep}
                                                setProcessingStep={setProcessingStep}
                                                reportId={item4.reportId}
                                                configurations={{ ...defaultReportConfigurations, reportConfiguration: item4.configuration }}
                                                noFilterMessage={getReportConfigurationValue(ReportConfigurationType.FiltersMissing, page.configuration)}
                                                key={index4}
                                                divId={`report${index4}`}
                                                setNotification={setNotification}
                                                filters={filters}
                                                setFooter={setFooter}
                                                filterReport={filterReport}
                                                setFilterReport={setFilterReport}
                                                minSize={300}
                                                pageSettings={pageSettings}
                                                reportHeight={reportHeight}
                                                isPublic={true}
                                                onFilterReport={onFilterReport}
                                                index={index4}
                                                aboutPages={page.groups.filter(q => q.isActive && q.groupType === ReportGroupType.AboutPage)}
                                            />
                                        )}

                                        {footer && (
                                            <div className="footer" ref={footerRef} dangerouslySetInnerHTML={displayHTMLContent(footer)} />
                                        )}
                                    </>
                                )}
                                {section.groups.length === 0 && section.id !== '' && (
                                    <OEMessage className="h5" type={MessageType.Danger} hideDismissable={true} message={`No groups have been set up for ${section.title} `} />
                                )}
                            </div>

                        </div>
                    </div>
                </>
            )}
        </div >
    );
};

export default MSEmbeddedPublic;
