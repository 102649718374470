import React, { useEffect, useState } from 'react';
import { ReportConfigurationGroupStyles, ReportConfigurationType, getReportConfigurationValue } from '../../../../reporting/entities/ReportConfiguration';
import { IReportGroup } from '../../../../reporting/entities/ReportGroup';
import SectionGroup from './SectionGroup';


interface ISectionTabInfo {
    index: number;
    group: IReportGroup;
    onClick: (i: IReportGroup) => void;
    selected: boolean;
}
const SectionTab: React.FunctionComponent<ISectionTabInfo> = ({ index, group, onClick, selected }) => {
    const onSelect = () => {
        onClick(group);
    }

    return (
        <button onClick={onSelect} id={`reports-tab-group${index}`} role="tab" data-rb-event-key={`group${index}`}
            aria-controls={`reports-tabpane-group${index}`} aria-selected="true"
            className={`nav-item nav-link ${selected ? 'active' : ''}`}>
            {group.title}
        </button>
    );
};

interface IComponentInfo {
    section: IReportGroup;
    activeGroup: IReportGroup;
    setActiveGroup: (i: IReportGroup) => void;
}

const SectionGroupList: React.FunctionComponent<IComponentInfo> = ({ section, setActiveGroup, activeGroup }) => {
    const [type, setType] = useState<string>(ReportConfigurationGroupStyles.Links);

    useEffect(() => {
        setType(getReportConfigurationValue(ReportConfigurationType.GroupStyle, section.configuration));
        // eslint-disable-next-line
    }, [section]);

    return (
        <>
            {type === ReportConfigurationGroupStyles.Links && (
                <>
                    <div className="group-links">
                        {section.groups.filter(q => q.isActive).map((i, index3) =>
                            <SectionGroup onClick={setActiveGroup} className={`${i.id === activeGroup.id ? 'active' : ''}`} key={index3} group={i} />
                        )}
                    </div>
                    <p className="subtitle m-t-10 m-l-10">{`${section.title}`} </p>
                    {section.description && <p className="description m-t-10 m-l-10">{`${section.description}`} </p>}

                </>
            )}
            {type === ReportConfigurationGroupStyles.Tabs && (
                <>
                    <p className="subtitle m-t-10 m-l-10">{`${section.title}`} </p>
                    <nav className="group-tabs fst-italic small m-t-10 m-b-0 nav nav-tabs mb-3 nav nav-tabs" role="tablist">
                        {section.groups.filter(q => q.isActive).map((g, index) =>
                            <SectionTab key={index} index={index} group={g} selected={activeGroup.id === g.id} onClick={setActiveGroup} />
                        )}
                    </nav>
                </>
            )}
        </>
    );
};

export default SectionGroupList;
