import React, { useEffect, useState } from 'react';
import OEFilter from '../core/components/filter/OEFilter';
import { IFilter } from '../core/components/filter/entities/Filter';
import OEHeading from '../core/components/general/OEHeading';
import OETable from '../core/components/table/OETable';
import { ColumnType, IColumn, ModifiedColumns } from '../core/components/table/entities/Column';
import { IColumnAction } from '../core/components/table/entities/ColumnAction';
import { Icon } from '../core/entities/Icon';
import UserFormik from './components/admin/UserFormik';
import UserPermissions from './components/admin/UserPermissions';
import { defaultPaginationFilter, defaultUser, getUserList, IUser, IUserPaginationFilter } from './entities/User';
import { useGetUsersByPaginationFilter } from './services/UserService';

enum ModalTypes {
    None = 1,
    Edit,
    Permissions,
}

const UserAdmin: React.FunctionComponent = () => {

    const { service,  setParams } = useGetUsersByPaginationFilter();

    const [items, setItems] = useState<IUser[]>([]);
    const [filterList, setFilterList] = useState<IUser[]>([]);
    const [item, setItem] = useState<IUser>(defaultUser);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [paginationParams, setPaginationParams] = useState<IUserPaginationFilter>(defaultPaginationFilter);
    const [startPage, setStartPage] = useState(1);

    useEffect(() => {
        if (service.result) {
            setItems(getUserList(service.result));
        }
    }, [service]);

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onSave = () => {
        setShowModal(ModalTypes.None);
        setParams(paginationParams);
    };

    const onEdit = (i: IUser) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onUpdateSecurity = (i: IUser) => {
        setItem(i);
        setShowModal(ModalTypes.Permissions);
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Permissions, onClick: onUpdateSecurity, helpText: 'Update Permissions' },
    ];
    const columns: IColumn[] = [
        { actions, id: 'Actions', width: '20px', name: '', sort: false, type: ColumnType.Actions, className: 'text-center' },
        { id: 'userName', idNewLine: 'id', name: 'Username', sort: true, type: ColumnType.Link, onClick: onEdit },
        { id: 'name', name: 'First Name', sort: true, type: ColumnType.String },
        { id: 'surname', name: 'Last Name', sort: true, type: ColumnType.String },
        { id: 'email', name: 'Email', sort: true, type: ColumnType.String },
        ...ModifiedColumns,
    ];

    const filter: IFilter = {
        name: 'userAdmin',
        autoSearch: true,
        filters: [
            { name: 'keyword', columns: ['userName', 'email', 'name', 'surname'], autoSearchLength: 3, autoSearch: true, label: 'Keyword', width: 500, placeholder: 'Search by username, email, first name, last name' },
        ],
    };

    const onPageChanged = (pageNumber: number, currentPage: number, pageSize: any) => {
        if(pageSize) {
            setPaginationParams({...paginationParams, maxResultCount: pageSize, skipCount: 0 });
            setParams({...paginationParams,  maxResultCount: pageSize, skipCount: 0 });
            setStartPage(currentPage);
        } else if((paginationParams.skipCount+1) !== pageNumber) {
            setPaginationParams({...paginationParams, skipCount: pageNumber-1});
            setParams({...paginationParams, skipCount: pageNumber-1});
            setStartPage(currentPage);
        } else {
            setPaginationParams({...defaultPaginationFilter});
            setParams({...defaultPaginationFilter});
            setStartPage(1);
        }
    }

    return (
        <div className="container">
            <OEHeading className="m-b-20 m-t-10" text="User Administration" icon={Icon.UserManagement} />
            <OEFilter isUser={true} singleLine={true} refresh={() =>setParams(defaultPaginationFilter)} onFilter={(value) =>{
                setPaginationParams({...paginationParams, filter: value});
                setParams({...paginationParams, filter: value});
            }} className="m-t-0" items={items} filter={filter} setFilterList={setFilterList} />
            <OETable
                data={filterList}
                columns={columns}
                showPagination={true}
                loading={service.isInProgress}
                loadingMessage="Loading Users"
                defaultSort="textCol"
                defaultPageSize={10}
                noDataMessage={`No users exists.`}
                includeRowNumber={true}
                recordCount={service.result?.totalCount}
                onPageChanged={onPageChanged}
                startPage={startPage}
            />

            {showModal === ModalTypes.Edit && (
                <UserFormik onCancel={onCancel} onSave={onSave} item={item} />
            )}

            {showModal === ModalTypes.Permissions && (
                <UserPermissions onCancel={onCancel} item={item} />
            )}
        </div>
    );
};

export default UserAdmin;