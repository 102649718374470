import React, { useEffect, useRef, useState } from 'react';
import OEMessage from '../../../../core/components/messaging/OEMessage';
import OENotification from '../../../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../../core/components/messaging/enums/InformationMessages';
import { displayHTMLContent } from '../../../../core/utilities/Miscellaneous';
import { parseQueryString, updateURLParameter } from '../../../../core/utilities/URL';
import { canDebugReport } from '../../../../reporting/entities/Report';
import { IReportConfigurations, ReportConfigurationType, defaultReportConfigurations, getReportConfigurationValue } from '../../../../reporting/entities/ReportConfiguration';
import { IReportGroup, ReportGroupType, defaultReportGroup } from '../../../../reporting/entities/ReportGroup';
import { IReportPage, defaultReportPage } from '../../../../reporting/entities/ReportPage';
import { ProcessingPage, logProcessingPage, updateProcessingStep } from '../../../../reporting/entities/ReportProcessing';
import { useGetReportPageTiered } from '../../../../reporting/services/ReportPageService';
import { IDossierSettings, defaultDossierSettings } from '../../../entities/MicroStrategyDossier';
import { IMicroStrategyFilter } from '../../../entities/MicroStrategyFilters';
import PageFilters from '../../common/PageFilters';
import PageTemplate from '../../common/PageTemplate';
import Report from '../../common/Report';
import AboutSection from '../../report-common/AboutSection';
import SectionGroup from './SectionGroup';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

const MSEmbeddedTierOne: React.FunctionComponent = () => {
    const params: any = parseQueryString();
    const [pageParameterId] = useState(params['pid'] || '');
    const footerRef = useRef<any>();

    const { service: pageService, setPageId } = useGetReportPageTiered(''); // Step 2 - get the page information

    const [debug] = useState<boolean>(canDebugReport);
    const [processingStep, setProcessingStep] = useState<ProcessingPage>(ProcessingPage.Idle);
    const [pageFilters, setPageFilters] = useState<IMicroStrategyFilter[]>([]);
    const [reportId, setReportId] = useState<string>('');
    const [notiication, setNotification] = useState<INotification>(defaultNotification);

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageSettings, setPageSettings] = useState<IDossierSettings>(defaultDossierSettings);
    const [group, setGroup] = useState<IReportGroup>(defaultReportGroup);
    const [error, setError] = useState<string>('');

    const [footer, setFooter] = useState<string>('');
    const [reportHeight, setReportHeight] = useState<number>(0);
    const [configurations, setConfigurations] = useState<IReportConfigurations>(defaultReportConfigurations);

    useEffect(() => {
        logProcessingPage(processingStep, debug);
        switch (processingStep) {
            case ProcessingPage.PageLoad:
                debug && setNotification({ message: 'Loading Page', type: 'info' });
                setPageId(pageParameterId);
                break;

            case ProcessingPage.PageLoaded:
                debug && setNotification({ message: 'Page Loaded', type: 'info' });
                break;

            case ProcessingPage.GroupLoadInitial:
                debug && setNotification({ message: 'Loading Group', type: 'info' });
                loadGroup();
                break;

            case ProcessingPage.GroupLoaded:
                updateProcessingStep(ProcessingPage.ReportLoad, setProcessingStep);
                debug && setNotification({ message: 'Group Loaded', type: 'info' });
                break;

            case ProcessingPage.ReportLoad:
                if (group.reports.length === 0) {
                    setProcessingStep(ProcessingPage.FatalError);
                    setError(`There are no reports setup for the report group: <b><i> ${group.title} </b></i>`);
                } else {
                    setReportId(group.reports[0].reportId);
                }
                break;

            case ProcessingPage.ReportLoaded:
                updateHeight();
                break;

            case ProcessingPage.Complete:
                break;

        }
        // eslint-disable-next-line
    }, [processingStep]);


    useEffect(() => {
        logProcessingPage(processingStep, debug);
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        if (page.id !== '') {
            updateProcessingStep(ProcessingPage.PageLoaded, setProcessingStep);
            setConfigurations({ ...configurations, pageConfiguration: page.configuration });
            if (!setGroupByID(params.gid)) {
                setGroup(page.groups[0]);
            }
        }
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (group.id !== '') {
            setProcessingStep(ProcessingPage.GroupLoadInitial);
            setConfigurations({ ...configurations, groupConfiguration: group.configuration });
        }
        // eslint-disable-next-line
    }, [group]);

    const loadGroup = () => {
        updateURLParameter("gid", group.id);
        setProcessingStep(ProcessingPage.GroupLoaded);
        setPageSettings({
            ...defaultDossierSettings,
            hideFilterSummary:
                getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.pageConfiguration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.sectionConfiguration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.groupConfiguration),
            collapsibleSections: getReportConfigurationValue(ReportConfigurationType.CollapsibleSections, configurations.pageConfiguration)
        });
        setFooter('');
    }
    const setGroupByID = (id: string): boolean => {
        for (const g of page.groups) {
            if (g.id === id) {
                setGroup(g);
                return true;
            }
        }
        return false;
    }

    const updateHeight = () => {
        setReportHeight(330);
    }
    const onChangeGroup = (i: IReportGroup) => {
        setReportHeight(0);
        setGroup(i);
    }

    return (
        <div className="report-embedded m-b-0 three-tier">
            <OENotification setNotification={setNotification} notification={notiication} />
            {debug && (
                <h5 className="text-success" style={{ position: 'absolute', top: '26px', left: '340px' }} >Page: {processingStep}</h5>
            )}

            <PageTemplate
                setNotification={setNotification}
                setProcessingStep={setProcessingStep}
                processingStep={processingStep}
            />
            <div className="content" style={{ height: `calc(100vh - 145px)` }}>
                {pageParameterId && pageParameterId !== '' && (
                    <>
                        <p className="subtitle m-t-10 m-l-10">{`${page.groups.length === 1 ? `${page.name} - ${page.groups[0].title}` : page.name}`}</p>
                        <div className="cleardiv" />

                        <PageFilters
                            setNotification={setNotification}
                            pageProcessingStep={processingStep}
                            reportId={reportId}
                            page={page}
                            setPageProcessingStep={setProcessingStep}
                            setPageFilters={setPageFilters}
                        />

                        {page.groups.length > 0 && (
                            <>
                                {processingStep === ProcessingPage.FatalError && (
                                    <OEMessage
                                        className="report-errors"
                                        hideDismissable={true}
                                        message={error}
                                        type={MessageType.Error}
                                    />
                                )}

                                {page.groups.filter(q => q.isActive && q.groupType !== ReportGroupType.AboutPage).length > 1 && (
                                    <nav className="fst-italic small m-b-0 nav nav-tabs mb-3 nav nav-tabs" role="tablist">
                                        {page.groups.filter(q => q.isActive).map((g, index) =>
                                            <SectionGroup key={index} index={index} group={g} selected={group.id === g.id} onClick={onChangeGroup} />
                                        )}
                                    </nav>
                                )}
                                {group.id !== '' && (
                                    <div style={{ height: `calc(100vh - 330px)` }}>
                                        {group.groupType === ReportGroupType.AboutPage && (
                                            <AboutSection group={group} />
                                        )}
                                        {group.reports.length > 0 && (
                                            <Report
                                                reportId={group.reports[0].reportId}
                                                setNotification={setNotification}
                                                pageFilters={pageFilters}
                                                pageProcessingStep={processingStep}
                                                divId={`report1`}
                                                configurations={{ ...configurations, reportConfiguration: group.reports[0].configuration }}
                                                pageSettings={pageSettings}
                                                reportHeight={reportHeight}
                                                groupType={group.groupType}
                                                index={0}
                                                multipleReports={getReportConfigurationValue(ReportConfigurationType.MultipleReports, configurations.groupConfiguration)}
                                            />

                                        )}
                                        {footer && (
                                            <div className="footer" ref={footerRef} dangerouslySetInnerHTML={displayHTMLContent(footer)} />
                                        )}

                                        {group.reports.length === 0 && (
                                            <OEMessage className="h5" type={MessageType.Danger} hideDismissable={true} message={`No report have been set up for ${group.title} `} />
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div >
    );
};

export default MSEmbeddedTierOne;
