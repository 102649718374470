import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { OECol } from '../../core/components/grid/OECol';
import { OERow } from '../../core/components/grid/OERow';
import OETable from '../../core/components/table/OETable';
import { ColumnType, IColumn } from '../../core/components/table/entities/Column';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import { Icon } from '../../core/entities/Icon';
import { IDcFormListItem } from '../entities/DcFormList';
import formStatus from '../lookups/dc-form-status.json';
import { IDcFormFilter, useGetDcFormsLists, usePutDcFormComplete, usePutDcFormFinalize } from '../services/DcFormListService';
import Filters from './Filters';
import { IConfirmationMessage, defaultConfirmationMessage } from '../../core/components/messaging/entities/ConfirmationMessage';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import OENotification from '../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../core/components/messaging/entities/Notification';

const DcFormsList: React.FunctionComponent = () => {
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState<boolean>(true);
    const [ forms, setForms ] = useState<IDcFormListItem[]>([]);
    const [ currentDcFilter, setCurrentDcFilter ] = useState<IDcFormFilter | null>(null);
    const [ confirmation, setConfirmation ] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [ notification, setNotification ] = useState<INotification>(defaultNotification);
    const { service: dcFormsService, setDcFormFilter } = useGetDcFormsLists();
    const { service: dcFormFinalizeService, setFinalizeId } = usePutDcFormFinalize();
    const { service: dcFormCompleteService, setCompleteId } = usePutDcFormComplete();

    const onEdit = (item: IDcFormListItem): void => {
        navigate(`/dc-forms-view?id=${item.id}&type=1`);
    }

    const onView = (item: IDcFormListItem): void => {
        navigate(`/dc-forms-view?id=${item.id}&type=2`);
    }

    const onFinalize = (i: string) => {
        setFinalizeId(i); 
    };

    const onComplete = (i: string) => {
        setCompleteId(i); 
    };

    const onConfirmFinalize = (i: IDcFormListItem) => {
        setConfirmation({
            setConfirmation,
            item: i.id,
            show: true,
            title: 'Confirm Form Finalize',
            message: `Are you sure you want to finalize this form? Finalizing this form will make it read-only and lock it to prevent any further changes. Once finalized, the status will be updated accordingly.`,
            onOk: onFinalize,
            onCancel: () => {},
        });
    };
    
    const onConfirmComplete = (i: IDcFormListItem) => {
        setConfirmation({
            setConfirmation,
            item: i.id,
            show: true,
            title: 'Confirm Form Completion',
            message: `Are you sure you want to complete this form?`,
            onOk: onComplete,
            onCancel: () => {},
        });
    }

    const report = {
        title: 'School Report Card - Data Entry', //TODO: Get this from ???
    };

    const actions: IColumnAction[] = [
        {
            icon: Icon.Edit,
            onClick: onEdit,
            helpText: 'Edit form',
            condition: 'status',
            conditionValue: 4,
            notCondition: true
        },
        {
            icon: Icon.View,
            onClick: onView,
            helpText: 'View form',
        },
        {
            icon: Icon.ThumbsUp,
            onClick: onConfirmComplete,
            helpText: 'Complete form',
            condition: 'status',
            conditionValue: 4,
            notCondition: true
        },
        {
            icon: Icon.Checked,
            onClick: onConfirmFinalize,
            helpText: 'Finalize form',
            condition: 'status',
            conditionValue: 3,
            notCondition: false
        }
    ];

    const columns: IColumn[] = [
        { id: 'name', name: 'Domain', sort: true, type: ColumnType.String },
        { id: 'statusName', name: 'Status', sort: true, type: ColumnType.String, },
        { id: 'lastModificationTime', name: 'Status Date', sort: true, type: ColumnType.Date, },
        { id: 'updatedByName', name: 'Updated By', sort: true, type: ColumnType.String },
        { actions, id: 'Actions', name: '', sort: false, type: ColumnType.Actions },
    ];

    const onDcFilterChange = (dcFormFilter: IDcFormFilter) => {
        setCurrentDcFilter(dcFormFilter);
        setDcFormFilter(dcFormFilter);
    };

    useEffect(() => {
        if (dcFormsService.result !== undefined && dcFormsService.result.items) {
            const list = dcFormsService.result.items.map((item: IDcFormListItem) => {
                if (!item.status) {
                    return item;
                }

                const itemWithStatus = { ...item, statusName: formStatus[item.status - 1]?.name };
                return itemWithStatus
            });

            setForms(list);
            setLoading(false);
        }
    }, [dcFormsService]);

    const refreshItems = () => {
        setDcFormFilter(currentDcFilter); //refresh the list
    };

    useEffect(() => {
        if (dcFormFinalizeService.isSuccess) {
            setNotification({
                message: "Form successfully finalized", type: 'success'
            });
            refreshItems();
        } else {
            if (dcFormFinalizeService.response.message) {
                setNotification({
                    message: dcFormFinalizeService.response.message, type: 'error'
                });
            }
        }
        // eslint-disable-next-line
    }, [dcFormFinalizeService]);

    useEffect(() => {
        if (dcFormCompleteService.isSuccess) {
            setNotification({
                message: "Form successfully completed", type: 'success'
            });
            refreshItems();
        } else {
            if (dcFormCompleteService.response.message) {
                setNotification({
                    message: dcFormCompleteService.response.message, type: 'error'
                });
            }
        }
        // eslint-disable-next-line
    }, [dcFormCompleteService]);

    return (
        <>
            <div style={{ marginLeft: '15px', marginRight: '15px', marginTop: '15px' }} >
                <OERow>
                    <OECol lg={6} md={12} sm={12}>
                        <h4 >{report.title}</h4>
                    </OECol>

                    <OECol lg={6} md={12} sm={12}>
                        <Filters setDcFormFilter={onDcFilterChange} />
                    </OECol>
                </OERow>

                <hr style={{ marginTop: '0px' }} />

                <OERow>
                    <OETable
                        loading={loading}
                        loadingMessage="Loading Forms"
                        data={forms}
                        columns={columns}
                        showPagination={(forms?.length || 0) > 10}
                        defaultSort="name"
                        noDataMessage="No Forms exist"
                        defaultPageSize={10}
                    />
                </OERow>

                <OEConfirmation {...confirmation} />
                <OENotification setNotification={setNotification} notification={notification} />
            </div>
        </>
    )
};

export default DcFormsList;