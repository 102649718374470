import React, { useEffect, useRef, useState } from 'react';
import OEIcon from '../../../core/components/general/OEIcon';
import { OECol } from '../../../core/components/grid/OECol';
import { OERow } from '../../../core/components/grid/OERow';
import OESpinner from '../../../core/components/messaging/OESpinner';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { Icon } from '../../../core/entities/Icon';
import { displayHTMLContent } from '../../../core/utilities/Miscellaneous';
import ReportDescription from '../../../reporting/components/ReportDescription';
import { IReport, canDebugReport, defaultReport } from '../../../reporting/entities/Report';
import { defaultReportAccessLog } from '../../../reporting/entities/ReportAccessLog';
import { IReportConfigurations, ReportConfigurationPaginationStyles, ReportConfigurationType, getReportConfigurationValue } from '../../../reporting/entities/ReportConfiguration';
import { IReportGroup } from '../../../reporting/entities/ReportGroup';
import { ProcessingPage, ProcessingStepLabel, reportLogToConsole, reportLogToConsoleNotification, updateProcessingStep } from '../../../reporting/entities/ReportProcessing';
import { useGetReport, useLogAccess } from '../../../reporting/services/ReportService';
import { IDossierCreation, IDossierSettings, IMSTRDossierFilter, defaultDossierCreation, defaultDossierSettings } from '../../entities/MicroStrategyDossier';
import { IMicroStrategyExportOptions, getExportOptions } from '../../entities/MicroStrategyExportOptions';
import { IMSFilter, checkDossierFilters, defaultMSFilter, dossierFilterIsPageFilter, getDossierFilterValue, getDossierMatchingPageFilter, getDossierMatchingReportFilter } from '../../entities/MicroStrategyFilters';
import { IMicroStrategyDossierChapterList, defaultMicroStrategyDossierChapterList } from '../../entities/MicrostrategyDossierChapter';
import { IReportBaseFilter, defaultReportBaseFilter, getJSONFromReportFilter } from '../../entities/ReportFilter';
import { applyDossierFilters, createDossier, createPublicDossier, exportDossier, getDossierChapterList, getDossierFilters } from '../../services/MicrostrategyDossierService';
import AboutSection from './AboutSection';
import Filters from './Filters';
import MicrostrategyPageLinks from './MicrostrategyPageLinks';
import MicrostrategyPageTabs from './MicrostrategyPageTabs';
import ReportExport from './ReportExport';


interface IReportFilterValue {
    label: string;
    name: string;
    displayValue: string;
    value: string;
}

interface IComponentInfo {
    processingStep: ProcessingPage;
    setProcessingStep: (i: ProcessingPage) => void;
    reportId: string;
    configurations: IReportConfigurations;
    pageSettings?: IDossierSettings;
    divId: string;
    setNotification: (n: INotification) => void;
    filters: IMSFilter[];
    filterReport: boolean;
    setFilterReport: (i: boolean) => void;
    minSize: number;
    reportHeight: number;
    noFilterMessage?: string;
    setFooter?: (i: string) => void;
    isPublic?: boolean;
    onFilterReport: () => void;
    aboutPages?: IReportGroup[];
    multipleReports?: boolean;
    index: number;
}

const Report: React.FunctionComponent<IComponentInfo> = ({
    divId, reportId, configurations, pageSettings, filters, setNotification, aboutPages,
    filterReport, setFilterReport, setFooter, reportHeight, processingStep, setProcessingStep, noFilterMessage, isPublic,
    onFilterReport, multipleReports, index
}) => {
    const timeoutDelay: number = parseFloat(localStorage.getItem('reportShowDelay') || '1') * 1000;
    const exportReportRef = useRef<any>();
    const { service: reportService, setItemId: setReportId } = useGetReport();

    const defaultWaitMessage: string = 'One moment please...'
    const [debug] = useState<boolean>(canDebugReport());
    const { setItem: logReport } = useLogAccess();
    const [report, setReport] = useState<IReport>(defaultReport);
    const [path, setPath] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [dossierHeight, setDossierHeight] = useState<string>('');
    const [minHeight, setMinHeight] = useState<string>('');
    const [maxHeight, setMaxHeight] = useState<string>('');
    const [minWidth, setMinWidth] = useState<string>('');
    const [maxWidth, setMaxWidth] = useState<string>('');
    const [initializing, setInitializing] = useState<boolean>(true);
    const [pageRendered, setPageRendered] = useState<boolean>(false);
    const [validFilters, setValidFilters] = useState<boolean>(true);
    const [showReport, setShowReport] = useState<boolean>(false);
    const [waitMessage, setWaitMessage] = useState<string>(defaultWaitMessage);
    const [chapterList, setChapterList] = useState<IMicroStrategyDossierChapterList>(defaultMicroStrategyDossierChapterList);
    const [dossierFilters, setDossierFilters] = useState<IMSFilter[]>([]);
    const [myFilters, setMyFilters] = useState<IMSFilter[]>([]);
    const [dossier, setDossier] = useState<any>();
    const [aboutIndex, setAboutIndex] = useState<number>(-1);
    const [exportOptions, setExportOptions] = useState<IMicroStrategyExportOptions[]>([]);
    const [localStep, setLocalStep] = useState<ProcessingPage>(ProcessingPage.NotDefined);
    const [paginationLinks, setPaginationLinks] = useState<boolean>(false);
    const [showDescription, setShowDescription] = useState<boolean>(false);

    useEffect(() => {
        switch (processingStep) {
            case ProcessingPage.ReportLoad:
            case ProcessingPage.LoadDossierFilters:
                window.setTimeout(() => {
                    setLocalStep(processingStep);
                }, 200); // this setTimeout is a workaround to prevent report showing before filters are applied.
                break;

            case ProcessingPage.FatalError:
                setProcessingStep(ProcessingPage.Complete);
                break;

        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        reportLogToConsoleNotification(debug, setNotification, { message: `${report.title} ${ProcessingStepLabel.get(localStep)}`, type: 'info' });

        switch (localStep) {
            case ProcessingPage.ReportLoad:
                setErrorMessage('');
                setInitializing(true);
                setValidFilters(true);
                setPageRendered(false);
                setFooter && setFooter('');
                setExportOptions([]);
                setPath('');
                setChapterList(defaultMicroStrategyDossierChapterList);
                setDossierFilters([...dossierFilters.filter(q => q.dossier)]);
                setReportId(reportId);
                setShowReport(false);
                break;

            case ProcessingPage.ReportLoaded:
                updateProcessingStep(ProcessingPage.LoadDossier, setLocalStep);
                setFooter && setFooter(getReportConfigurationValue(ReportConfigurationType.ReportFoooter, report.configuration));
                setExportOptions(getExportOptions(report.configuration));
                break;

            case ProcessingPage.LoadDossier:
                onLoadDosser();
                break;

            case ProcessingPage.LoadDossierFilters:
                getDossierFilters(setDossierFilterValues, dossier);
                getDossierChapterList(setChapterList, dossier);
                break;

            case ProcessingPage.ApplyFiltersAfterUpdate:
                getDossierFilters(setDossierFilterValues, dossier);
                break;

            case ProcessingPage.ApplyFilters:
                applyFilters();
                break;

            case ProcessingPage.Finished:
                setProcessingStep(localStep);
                setInitializing(false);
                if (!showReport) {
                    window.setTimeout(() => {
                        setShowReport(true);
                    }, timeoutDelay); // this setTimeout is a workaround to prevent report showing before filters are applied.
                }
                break;

            case ProcessingPage.UpdateDossierFilters:
                getDossierFilters(addDossierFilters, dossier)
                break;

            case ProcessingPage.DossierFiltersLoaded:
                setWaitMessage(defaultWaitMessage);

                if (initializing) {
                    updateProcessingStep(ProcessingPage.ApplyFilters, setLocalStep);
                    if (initializing && pageRendered) {
                        window.setTimeout(() => {
                            setInitializing(false);
                        }, 1000); // this setTimeout is a workaround for preloaded reports
                    }
                }
                else {
                    updateProcessingStep(ProcessingPage.ApplyFilters, setLocalStep);
                }
                break;

        }
        // eslint-disable-next-line
    }, [localStep]);

    useEffect(() => {
        if (minHeight === '0px') {
            setMinHeight('');
        }
        // eslint-disable-next-line
    }, [minHeight]);

    useEffect(() => {
        if (maxHeight === '0px') {
            setMaxHeight('');
        }
        // eslint-disable-next-line
    }, [maxHeight]);

    useEffect(() => {
        if (minWidth === '0px') {
            setMinWidth('');
        }
        // eslint-disable-next-line
    }, [minWidth]);

    useEffect(() => {
        if (maxWidth === '0px') {
            setMaxWidth('');
        }
        // eslint-disable-next-line
    }, [maxWidth]);

    useEffect(() => {
        if (reportService.result) {
            !isPublic && logReport({ ...defaultReportAccessLog, reportId: reportService.result.report.id });
            setReport(reportService.result.report);
            setPath(isPublic ? `${localStorage.getItem("msPublicPath")}/app` : reportService.result.reportServer.path);
            updateProcessingStep(ProcessingPage.ReportLoaded, setLocalStep);
            setMinHeight(`${getReportConfigurationValue(ReportConfigurationType.MinReportHeight, reportService.result.report.configuration, configurations.groupConfiguration)}px`);
            setMaxHeight(`${getReportConfigurationValue(ReportConfigurationType.MaxReportHeight, reportService.result.report.configuration, configurations.groupConfiguration)}px`);
            setMinWidth(`${getReportConfigurationValue(ReportConfigurationType.MinReportWidth, reportService.result.report.configuration, configurations.groupConfiguration)}px`);
            setMaxWidth(`${getReportConfigurationValue(ReportConfigurationType.MaxReportWidth, reportService.result.report.configuration, configurations.groupConfiguration)}px`);
            setPaginationLinks(getReportConfigurationValue(ReportConfigurationType.PaginationStyle, configurations.pageConfiguration, configurations.groupConfiguration) === ReportConfigurationPaginationStyles.Links);
        }
        // eslint-disable-next-line
    }, [reportService]);


    useEffect(() => {
        setMyFilters(dossierFilters);
        // eslint-disable-next-line
    }, [dossierFilters]);

    useEffect(() => {
        const percent: number = getReportConfigurationValue(ReportConfigurationType.ReportHeightPercentage, configurations.reportConfiguration);
        if (percent === 100) {
            setDossierHeight(`calc(100vh - ${reportHeight + (dossierFilters.filter(q => q.isActive).length > 0 ? 10 : 0) + (chapterList.totalPages > 1 ? 20 : 0)}px)`);
        }
        else {
            setDossierHeight(`calc(${percent}% - ${reportHeight}px)`);
        }
        // setNotification({ message: `totalHeight ${reportHeight}`, type: 'info' });
        // eslint-disable-next-line
    }, [reportHeight, chapterList, dossierFilters]);

    useEffect(() => {
        if (dossier) {
            reportLogToConsoleNotification(debug, setNotification, { message: `Dossier Change ${processingStep}`, type: 'info' });
            updateProcessingStep(ProcessingPage.ApplyFilters, setLocalStep);
            //getDossierFilters(setDossierFilters, dossier);
        }
        // eslint-disable-next-line
    }, [dossier]);

    useEffect(() => {
        let b: boolean = false;
        for (const o of exportOptions) {
            if (o.export) {
                const i: string[] = report.externalId.split(/\//);
                exportDossier(`https://${path.split(/\//)[2]}`, 'MicroStrategyLibrary', i[0], i[1], dossier.instanceId, o.filename, o.type.toString());
                o.export = false;
                b = true;
                setNotification({ message: `Export is processing....`, type: 'info' })
            }
        }
        if (b) {
            setExportOptions([...exportOptions]);
        }
        // eslint-disable-next-line
    }, [exportOptions]);

    useEffect(() => {
        filterReport && applyFilters();
        setFilterReport(false);
        // eslint-disable-next-line
    }, [filterReport]);

    useEffect(() => {
        if (!initializing) {
            reportLogToConsoleNotification(debug, setNotification, { message: `Report Has Been Initialized`, type: 'info' });
        }
        // eslint-disable-next-line
    }, [initializing]);

    const onShowDescription = () => {
        setShowDescription(true);
    }
    const onHideDescription = () => {
        setShowDescription(false);
    }

    const onLoadDosser = () => {
        if (report.id !== '' && path !== '') {
            const i: IDossierCreation = {
                ...defaultDossierCreation,
                dossierChanged,
                debug,
                divId,
                errorHandler: onError,
                url: `${path}/${report.externalId}`,
                settings: {
                    ...defaultDossierSettings,
                    showNavigationBar: getReportConfigurationValue(ReportConfigurationType.ShowNavgiationBar, configurations.reportConfiguration, report.reportConfiguration),
                    showFilters: getReportConfigurationValue(ReportConfigurationType.ShowFilters, configurations.reportConfiguration, report.reportConfiguration),
                    hideFilterSummary: getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.reportConfiguration, report.reportConfiguration) || (pageSettings ? pageSettings.hideFilterSummary : true)
                },
                pageRendered: pageEvent,
                filtersUpdated: filtersUpdated,
                pageSwitched: pageSwitchedEvent,
            };
            isPublic ? createPublicDossier(setDossier, i) : createDossier(setDossier, { ...i, baseUrl: `https://${path.split(/\//)[2]}` });
            //setDossierHeight(window.innerHeight - 280);
            //setDossierHeight(305 + (filters.filter(q => q.dossier).length > 0 ? 40 : 0));
        }
    }

    const onError = (e: any) => {
        reportLogToConsoleNotification(debug, setNotification, { message: `${ProcessingStepLabel.get(processingStep)} ${e.message}`, type: 'info' });
        setErrorMessage(`${e.message}`);
        updateProcessingStep(ProcessingPage.Finished, setLocalStep);
        setWaitMessage(`${waitMessage}.`);
        window.setTimeout(() => {
            // updateProcessingStep(ReportProcessingStep.LoadReport, setProcessingStep);;
        }, 5000); // this setTimeout is a workaround for invalid sessions
    }

    const setDossierFilterValues = (i: IMSTRDossierFilter[]) => {
        for (let f of dossierFilters.filter(q => q.dossier)) {
            checkDossierFilters(i, f, false);
        }
        updateProcessingStep(ProcessingPage.DossierFiltersLoaded, setLocalStep);
    }

    const dossierChanged = (e: any) => {
        reportLogToConsoleNotification(debug, setNotification, { message: `Instance ${e}`, type: 'info' });
        //updateProcessingStep(ReportProcessingStep.LoadDossierFilters);
    }

    const filtersUpdated = (e: any) => {
        window.setTimeout(() => {
            setShowReport(true);
        }, timeoutDelay); // this setTimeout is a workaround to prevent report showing before filters are applied.

        // check for filter changes in dossier
        let changed: boolean = false;
        let updateValues: boolean = false;
        for (const d of dossierFilters) {
            const f: IMSTRDossierFilter[] = e.filterInfo.filter((q: { filterKey: string; }) => q.filterKey === d.key);
            updateValues = false;
            if (f.length > 0) {
                if (f.values.length !== d.values.length) {
                    updateValues = true;
                }
                else {
                    for (const i of f[0].filterDetail.items) {
                        if (d.values.filter(q => q.id !== i.value).length > 0) {
                            updateValues = true;
                        }
                    }
                    for (const i of d.values) {
                        if (f[0].filterDetail.items.filter(q => q.value !== i.id).length > 0) {
                            updateValues = true;
                        }
                    }
                }
                if (updateValues) {
                    d.values = [];
                    d.value = '';
                    for (const i of f[0].filterDetail.items) {
                        d.values.push({ id: i.value, name: i.name, parentId: '' });
                        if (i.selected) {
                            d.value = i.value;
                        }
                    }
                    changed = true;
                }
            }

        }
        if (changed) {
            setDossierFilters([...dossierFilters]);
        }
        reportLogToConsoleNotification(debug, setNotification, { message: `ON_FILTER_UPDATED`, type: 'info' });
    }

    const pageEvent = (e: any) => {
        reportLogToConsoleNotification(debug, setNotification, { message: `ON_PAGE_RENDER_FINISHED`, type: 'info' });
        // window.setTimeout(() => {
        // }, 1000); // this delay is necssary to prevent initial loading of report from being shown
    }

    const pageSwitchedEvent = (e: any) => {
        reportLogToConsoleNotification(debug, setNotification, { message: `ON_PAGE_SWITCHED`, type: 'info' });
        onPageChanged();
    }

    const onPageChanged = () => {
        window.setTimeout(() => {
            updateProcessingStep(ProcessingPage.UpdateDossierFilters, setLocalStep);
        }, 3000); // this delay is necssary to prevent initial loading of report from being shown
    }

    const addDossierFilters = (i: IMSTRDossierFilter[]) => {
        //if (dossier && dossier.filters) {
        //    dossier.filters = i;
        //}

        const rf: IReportBaseFilter[] = getJSONFromReportFilter(report.filters);
        const newFilters: IMSFilter[] = dossierFilters || [];
        const existingValues: IReportFilterValue[] = [];

        // get saved filter values to pass to new report.
        for (const c of newFilters.filter(q => q.isActive)) {
            let displayValue: string = '';
            if (c.values.filter(q => q.id === c.value).length > 0) {
                displayValue = c.values.filter(q => q.id === c.value)[0].name;
            }
            existingValues.push({ displayValue, name: c.name, label: c.label, value: c.value });
        }

        // set all filters to no active
        for (const c of newFilters) {
            c.isActive = false;
        }

        for (const c of i) {
            if (newFilters.filter(q => q.key === c.filterKey).length !== 0) {
                newFilters.filter(q => q.key === c.filterKey)[0].isActive = true;
            }
            else {
                let match: IReportBaseFilter = rf.find(q => q.externalId?.toLowerCase() === c.filterName?.toLowerCase()) || { ...defaultReportBaseFilter };
                if (match.name === '') {
                    match = rf.find(q => q.name?.toLowerCase() === c.filterName?.toLowerCase()) || { ...defaultReportBaseFilter };
                }
                if (match.name === '') {
                    match = rf.find(q => q.msId?.toLowerCase() === c.filterName?.toLowerCase()) || { ...defaultReportBaseFilter };
                }
                let v: string = '';
                if (existingValues.filter(q => q.label === c.filterName).length > 0) {
                    const e = existingValues.filter(q => q.label === c.filterName)[0];
                    for (const v2 of c.filterDetail.items) {
                        if (v2.name === e.displayValue) {
                            v = v2.value;
                        }
                    }
                }
                const m: IMSFilter = {
                    ...defaultMSFilter,
                    name: c.filterName,
                    key: c.filterKey,
                    label: match.label || c.filterName,
                    dossier: (match.name === '' || match.dossier) && !dossierFilterIsPageFilter(c, filters),
                    filterType: c.filterType,
                    filterDetail: c.filterDetail,
                    noFilterText: match.noFilterText,
                    ignoreFilter: match.ignoreFilter,
                    supportMultiple: c.filterDetail.supportMultiple,
                    defaultSelection: match.defaultSelection || '',
                    value: v || match.defaultSelection || '',
                    sortOrder: match.sortOrder || 0,
                };
                m.values = [];

                for (const z of c.filterDetail.items) {
                    m.values.push({ id: z.value, name: z.name });
                }
                if (!m.ignoreFilter) {
                    newFilters.push(m);
                }
            }
        }

        // update new filters with saved values

        newFilters.sort(function (x, y) {
            return x.sortOrder - y.sortOrder;
        });

        setDossierFilters && setDossierFilters([...newFilters]);
        processingStep !== ProcessingPage.LoadDossier && updateProcessingStep(ProcessingPage.ApplyFiltersAfterUpdate, setLocalStep);

        //!initial && setFilters([...filters]);
        // updateProcessingStep(ReportProcessingStep.LoadDossierFilters, setProcessingStep);
    }

    const applyFilters = () => {
        setPageRendered(true);
        if (filters.length > 0 && dossier && dossier.filters && dossier.filters.length > 0) {
            let bValid: boolean = true;
            reportLogToConsole(debug, '-------', getJSONFromReportFilter(report.filters));
            setFooter && setFooter(getReportConfigurationValue(ReportConfigurationType.ReportFoooter, report.configuration));
            const reportFilters = getJSONFromReportFilter(report.filters);
            if (dossierFilters) {
                reportLogToConsole(debug, `${report.filters}`);
                reportLogToConsole(debug, `${report.title} -> used before override`, [...dossierFilters], [...filters], [...dossier.filters]);

                // check for ms filter name overrides (we have to check page filters and report filters (report filters override page filters))
                for (const i of dossierFilters.filter(q => q.isActive)) {
                    const f: IMSFilter = getDossierMatchingPageFilter(i, filters.filter(q => q.isActive), reportFilters);
                    const r: IReportBaseFilter = getDossierMatchingReportFilter(i, reportFilters);
                    if (f.name !== '') {
                        i.overideValue = r.name !== '' ? r.overrideValue : f.overideValue;
                        i.showSelectAll = f.showSelectAll;
                        i.selectAllText = f.selectAllText;
                        i.cascadePriority = f.cascadePriority || 99;
                        i.rangeIncrements = r.name !== '' ? r.rangeIncrements : f.rangeIncrements;
                        i.filterAlignment = f.filterAlignment;
                        i.noFilterText = f.noFilterText;
                        if (!f.dossier) {
                            const i2: string = getDossierFilterValue(i, f, filters);
                            i.value = i2;
                        }
                        else {
                            const c: string = f.values.filter(q => q.id === f.value)[0].name;
                            i.value = i.values.filter(q => q.name === c).length > 0 ? i.values.filter(q => q.name === c)[0].id : '';
                        }
                        i.defaultSelection = f.defaultSelection;
                    }
                }
                reportLogToConsole(debug, `dossier filters`, [...dossierFilters], 'filters', [...filters], 'dossier', [...dossier.filters]);

                if (!pageRendered) {
                    for (const f of dossierFilters.filter(q => q.dossier && q.isActive)) {
                        if (f.defaultSelection && f.defaultSelection !== '') {
                            console.log('setting default selection');
                            f.value = f.defaultSelection;
                        }
                    }
                }

                // If all filters are valid apply filters to dossier
                setValidFilters(bValid);
                if (bValid) {
                    applyDossierFilters(dossierFilters.filter(q => q.isActive), dossier);
                    setProcessingStep(pageRendered ? ProcessingPage.Finished : ProcessingPage.LoadDossierFilters);
                }
                else {
                    setProcessingStep(ProcessingPage.Finished);
                    if (initializing) {
                        window.setTimeout(() => {
                            setInitializing(false);
                        }, 1000); // this setTimeout is a workaround for preloaded reports
                    }
                }
            }

            // getDossierFiltersList(dossier);
            reportLogToConsole(debug, 'Portal Filters', dossierFilters, 'MS Filters', dossier.filters);
        }
        else {
            if (processingStep === ProcessingPage.ApplyFilters) {
                setProcessingStep(ProcessingPage.Finished);
            }
        }
    }
    const updateFilterValues = (f: IMSFilter[], level: number, isCascading: boolean, dossier: boolean) => {
        updateProcessingStep(ProcessingPage.LoadDossierFilters, setLocalStep);
        // hideApply && setFilterReport(true);
    }

    const onExportReport = (i: IMicroStrategyExportOptions) => {
        exportOptions.filter(q => q.type === i.type)[0].export = true;
        setExportOptions([...exportOptions]);
    }


    return (
        <div>
            {showDescription && (
                <ReportDescription title={report.title} description={report.description} onCancel={onHideDescription} />
            )}
            <OESpinner message={waitMessage} hidden={(!initializing && showReport) || index > 0} />
            <div style={{ display: initializing || !showReport ? 'none' : 'block' }} >
                <div className={`${paginationLinks ? 'report-title-links' : 'report-title'}`}>
                    <OERow>
                        <OECol sm={paginationLinks ? 4 : 12}>
                            {multipleReports && (
                                <>
                                    {report.title}
                                    {report.description && report.description.length > 0 && (
                                        <OEIcon onClick={onShowDescription} className=" report-description-link m-l-5" icon={Icon.ReportDescriptionLink} />
                                    )}
                                </>
                            )}
                        </OECol>
                        {paginationLinks && (
                            <OECol className="text-right" sm={8}>
                                <MicrostrategyPageLinks
                                    onUpdateFilters={addDossierFilters}
                                    chapterList={chapterList}
                                    dossier={dossier}
                                    setNotification={setNotification}
                                    onPageChanged={onPageChanged}
                                    aboutPages={aboutPages}
                                    reportID={report.id}
                                    aboutIndex={aboutIndex}
                                    setAboutIndex={setAboutIndex}
                                />
                            </OECol>
                        )}
                    </OERow>
                    {exportOptions.map((i, index) =>
                        <ReportExport exportOption={i} key={index} exportReport={onExportReport} />
                    )}
                </div>

                {!paginationLinks && (
                    <MicrostrategyPageTabs
                        onUpdateFilters={addDossierFilters}
                        chapterList={chapterList}
                        dossier={dossier}
                        setNotification={setNotification}
                        onPageChanged={onPageChanged}
                        aboutPages={aboutPages}
                        reportID={report.id}
                        aboutIndex={aboutIndex}
                        setAboutIndex={setAboutIndex}
                    />
                )}
                {aboutIndex < 0 && (
                    <Filters
                        updateFilterValues={updateFilterValues}
                        level={2}
                        dossier={true}
                        hideApply={true}
                        filterReport={onFilterReport}
                        filters={myFilters}
                        setFilters={setDossierFilters}
                    />
                )}
            </div>
            {errorMessage === 'Session Not Valid' && (
                <iframe style={{ width: `0`, height: `0`, }} title="Login Check"
                    src={`https://${path.split(/\//)[2]}/MicroStrategyLibrary/auth/login-dialog.jsp?loginMode=4194304`}
                />
            )}

            {!validFilters && (
                <div className="report-no-filter">
                    <OEIcon icon={Icon.Error} />
                    <span dangerouslySetInnerHTML={displayHTMLContent(noFilterMessage || 'Filters chosen do not exist for this dossier')} />
                </div >
            )}

            {aboutPages && aboutIndex >= 0 && (
                <AboutSection group={aboutPages[aboutIndex]} />
            )}

            <div>
                <div ref={exportReportRef} style={{
                    marginBottom: '1px',
                    marginTop: '5px',
                    height: dossierHeight,
                    minHeight: validFilters && showReport ? minHeight : 0,
                    maxHeight: validFilters && showReport ? maxHeight : 0,
                    minWidth: validFilters && showReport ? minWidth : 0,
                    maxWidth: validFilters && showReport ? maxWidth : 0
                }}>
                    <div className={`${initializing ? 'three-tier-closed' : 'three-tier-open'}`} id={divId} />
                </div>
            </div>
        </div>
    );
};


export default Report;
