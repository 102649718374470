/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { IDropdownValuesSorted } from '../../core/components/form/OEDropdown';
import OEMessage from '../../core/components/messaging/OEMessage';
import OESpinner, { SpinnerStyle } from '../../core/components/messaging/OESpinner';
import { INotification } from '../../core/components/messaging/entities/Notification';
import { MessageType } from '../../core/components/messaging/enums/InformationMessages';
import Filters from '../../report-microstrategy/components/common/Filters';
import { IMicroStrategyFilter } from '../../report-microstrategy/entities/MicroStrategyFilters';
import { isSuppressionFilter } from '../../report-microstrategy/entities/MicrostrategyDossierFilter';
import { IReportFilter } from '../../report-microstrategy/entities/ReportFilter';
import { CubeAttributeElementResponseSeparator, ICubeFilterAttribute, getCubeAttributeRawValueFromKey } from '../../report-microstrategy/entities/api/CubeAttributeElementResponse';
import { ICubeAttributeRequest, getDefaultCubeAttributeRequest } from '../../report-microstrategy/entities/api/CubeAttributeRequest';
import { ICubeAttributeResponse, defaultCubeAttributeResponse, getCubeAttributesfromAttributeResponse } from '../../report-microstrategy/entities/api/CubeAttributeResponse';
import { ICubeRequest } from '../../report-microstrategy/entities/api/CubeRequest';
import { ICubeAttribute } from '../../report-microstrategy/entities/api/CubeResponse';
import { usePostCubeAttributeElements } from '../../report-microstrategy/services/MicrostrategyService';
import { IReport, canDebugReport, getReportCubeRequest } from '../../reporting/entities/Report';
import { IReportPage } from '../../reporting/entities/ReportPage';
import { ProcessingDossierFilters, ProcessingReport, getProcessingDossierFiltersLabel, logProcessingDossierFilters } from '../../reporting/entities/ReportProcessing';

interface IDossierFiltersInfo {
    report: IReport;
    page?: IReportPage;
    pageFilters: IMicroStrategyFilter[];
    cubeAttributes: ICubeAttribute[];
    setNotification: (n: INotification) => void;
    reportProcessingStep: ProcessingReport;
    setReportProcessingStep: (i: ProcessingReport) => void;
    setDossierFilters: (i: IMicroStrategyFilter[]) => void;
    setDebugMessage?: (i: string) => void;
}

const DossierFilters: React.FunctionComponent<IDossierFiltersInfo> = ({
    report, page, setNotification, setReportProcessingStep, setDossierFilters,
    reportProcessingStep, cubeAttributes, setDebugMessage, pageFilters
}) => {

    const { service: cubePostAttributeService, setCube: setPostCubeAttributeElements } = usePostCubeAttributeElements();

    const [processingStep, setProcessingStep] = useState<ProcessingDossierFilters>(ProcessingDossierFilters.Idle);
    const [filters, setFilters] = useState<IMicroStrategyFilter[]>([]);
    const [reportFilters, setReportFilters] = useState<IReportFilter[]>([]);

    const [debug] = useState<boolean>(canDebugReport);
    const [message, setMessage] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [filterLoadIndex, setFilterLoadIndex] = useState<number>(-1);
    let [cubeAttributeRequest, setCubeAttributeRequest] = useState<ICubeAttributeRequest>(getDefaultCubeAttributeRequest);
    const [cubeAttributeResponse, setCubeAttributeResponse] = useState<ICubeAttributeResponse>(defaultCubeAttributeResponse);
    const [filterAttributes, setFilterAttributes] = useState<ICubeFilterAttribute[]>([]);

    useEffect(() => {
        switch (reportProcessingStep) {

            case ProcessingReport.DossierFiltersLoad:
                setProcessingStep(ProcessingDossierFilters.Start);
                break;
        }
        // eslint-disable-next-line
    }, [reportProcessingStep]);

    useEffect(() => {
        logProcessingDossierFilters(processingStep, debug);
        setDebugMessage?.(getProcessingDossierFiltersLabel(processingStep));

        if (reportProcessingStep === ProcessingReport.FatalError) {
            setProcessingStep(ProcessingDossierFilters.FatalError);
            return;
        }
        switch (processingStep) {
            case ProcessingDossierFilters.Start:
                let f: IReportFilter[] = [];
                if (reportFilters.length > 0) {
                    f = reportFilters;
                }
                else {
                    if (page) {
                        f = JSON.parse(page.filters);
                    }
                    else {
                        f = JSON.parse(report.filters);
                    }
                }
                for (const c of f) {
                    c.filterLoaded = false;
                }
                setReportFilters(f.filter(q => q.dossier));
                break;

            case ProcessingDossierFilters.FiltersLoaded:
                initializeReportFilters();
                break;

            case ProcessingDossierFilters.CubeFilterStart:
                const c: ICubeAttributeRequest = { ...getDefaultCubeAttributeRequest() };
                if (pageFilters.length > 0) {
                    const m: IMicroStrategyFilter = pageFilters[pageFilters.length - 1];
                    let key: string = '';
                    let selection: string = m.selection;
                    for (const a of cubeAttributes) {
                        for (const s of m.externalId.split(',')) {
                            if (s.trim().toLowerCase() === a.name.trim().toLowerCase()) {
                                key = a.id;
                                break;
                            }
                        }
                        if (key !== '') {
                            break;
                        }
                    }
                    selection = selection.replace(m.key, key || m.key);
                    if (selection !== '') {
                        c.viewFilter.operands.push({ operator: 'In', operands: [] });
                        c.viewFilter.operands[c.viewFilter.operands.length - 1].operands.push({ type: 'attribute', id: key });
                        c.viewFilter.operands[c.viewFilter.operands.length - 1].operands.push({ type: 'elements', elements: [{ id: getCubeAttributeRawValueFromKey(selection) }] });
                    }
                }
                setCubeAttributeRequest({ ...c });
                setFilterLoadIndex(0);
                break;

            case ProcessingDossierFilters.CubeFilterLoadNext:
                if (filterLoadIndex < reportFilters.length) {
                    if (filterLoadIndex > 0) {
                        const rf: IReportFilter = reportFilters[filterLoadIndex - 1];
                        const ca: ICubeAttribute = cubeAttributes.filter(q => q.id === rf.msId)[0];
                        const value: string = ca.elements.filter(q => q.key === rf.value)[0].id;
                        cubeAttributeRequest.viewFilter.operands.push({ operator: 'In', operands: [] });
                        cubeAttributeRequest.viewFilter.operands[cubeAttributeRequest.viewFilter.operands.length - 1].operands.push({ type: 'attribute', id: rf.msId });
                        cubeAttributeRequest.viewFilter.operands[cubeAttributeRequest.viewFilter.operands.length - 1].operands.push({ type: 'elements', elements: [{ id: value }] });
                        setCubeAttributeRequest({ ...cubeAttributeRequest });
                    }
                    else {
                        setProcessingStep(ProcessingDossierFilters.CubeFilterLoading);
                    }
                } else {
                    setProcessingStep(ProcessingDossierFilters.CubeFilterComplete);
                }
                break;

            case ProcessingDossierFilters.CubeFilterLoading:
                setDebugMessage?.(`Cube Filter Load - ${reportFilters[filterLoadIndex].name}`);
                const rf: IReportFilter = reportFilters[filterLoadIndex];
                if (isSuppressionFilter(rf.name)) {
                    setFilterLoadIndex(filterLoadIndex + 1);
                }
                else {

                    cubeAttributeRequest.requestedObjects.attributes = [];
                    cubeAttributeRequest.requestedObjects.attributes.push({ id: rf.msId });

                    const b: ICubeRequest = {
                        ...getReportCubeRequest(report),
                        id: cubeAttributes.filter(q => q.id === reportFilters[filterLoadIndex].msId)[0].cubeId,
                        body: cubeAttributeRequest,
                        run: true
                    };
                    setPostCubeAttributeElements(b);
                }
                break;

            case ProcessingDossierFilters.CubeFilterComplete:
                setFilterLoadIndex(-1);;
                setProcessingStep(ProcessingDossierFilters.UpdateFilters);
                break;

            case ProcessingDossierFilters.UpdateFilters:
                const d: IMicroStrategyFilter[] = [];
                for (const c of reportFilters) {
                    let s: string = '';
                    if (c.values) {
                        let f = c.values.filter(q => q.id === c.value);
                        if (f && f.length > 0) {
                            s = f[0].parentId;
                        }
                    }
                    d.push({
                        key: c.msId,
                        selection: s,
                        name: c.msName,
                        externalId: c.externalId,
                        pageFilter: true,
                        values: c.values,
                        value: c.value,
                        label: c.label,
                        cascadePriority: c.cascadePriority,
                        sortOrder: c.sortOrder,
                        displayValue: c.value,
                        supportMultiple: c.mulitipleSelect,
                        rangeIncrements: c.rangeIncrements || "2",
                        isSuppression: isSuppressionFilter(c.msName),
                    });
                }
                setDossierFilters(d);
                setFilters(d);
                setProcessingStep(ProcessingDossierFilters.Complete);
                break;

            case ProcessingDossierFilters.Complete:
                setReportProcessingStep(ProcessingReport.DossierFiltersLoaded);
                setMessage('');
                break;

            case ProcessingDossierFilters.FatalError:
                setMessage('')
                setDossierFilters([]);
                setReportProcessingStep(ProcessingReport.FatalError);
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        switch (processingStep) {
            case ProcessingDossierFilters.Start:
                setProcessingStep(ProcessingDossierFilters.FiltersLoaded);
                break;
            case ProcessingDossierFilters.FiltersLoaded:
                setProcessingStep(ProcessingDossierFilters.CubeFilterStart);
                break;
        }
        // eslint-disable-next-line
    }, [reportFilters]);

    useEffect(() => {
        if (filterLoadIndex >= 0) {
            setProcessingStep(ProcessingDossierFilters.CubeFilterLoadNext);
        }
        // eslint-disable-next-line
    }, [filterLoadIndex]);

    useEffect(() => {
        switch (processingStep) {
            case ProcessingDossierFilters.CubeFilterStart:
                setProcessingStep(ProcessingDossierFilters.CubeFilterLoadNext);
                break;
            case ProcessingDossierFilters.CubeFilterLoadNext:
                setProcessingStep(ProcessingDossierFilters.CubeFilterLoading);
                break;
        }
        // eslint-disable-next-line
    }, [cubeAttributeRequest]);

    useEffect(() => {
        if (!cubePostAttributeService.isInProgress) {
            if (cubePostAttributeService.body) {
                setCubeAttributeResponse(cubePostAttributeService.body);
            }
            if (cubePostAttributeService.error) {
                setNotification({ message: `Getting Cube Attribute Elements (DossierFilters) ${reportFilters[filterLoadIndex].name} ${cubePostAttributeService.error}`, type: 'error' })
                setFilterLoadIndex(filterLoadIndex + 1);
            }
        }
        // eslint-disable-next-line
    }, [cubePostAttributeService]);

    useEffect(() => {
        try {
            if (cubeAttributeResponse.result) {
                setFilterAttributes(getCubeAttributesfromAttributeResponse(cubeAttributeResponse.result.data.root.children));
            }
        }
        catch (e: any) {
            setNotification({ message: `Report (cubeAttributeResponse) ${e.message}`, type: 'error' })
            console.log(cubeAttributeResponse.result);
        }
        // eslint-disable-next-line
    }, [cubeAttributeResponse]);

    useEffect(() => {
        if (filterAttributes.length > 0) {
            const rf: IReportFilter = reportFilters[filterLoadIndex];
            if (rf) {
                rf.values = [];
                const sortedValues: IDropdownValuesSorted[] = [];
                let match = cubeAttributes.find(q => q.name === rf.name);
                if (match && match.elements) {
                    for (const r of filterAttributes) {
                        let value = match.elements.find(q => q.key === r.id);
                        if (value) {
                            let sort: number = -1;
                            try {
                                if (rf.rawValueSortIndex >= 0) {
                                    const s: string[] = value.rawValue.split(CubeAttributeElementResponseSeparator);
                                    sort = parseInt(s[rf.rawValueSortIndex], 10);
                                }
                            }
                            catch {
                                sort = -1;
                            }
                            sortedValues.push({ sort, id: r.id, name: value.value, parentId: r.id });
                        }
                    }
                    if (rf.rawValueSortIndex >= 0) {
                        sortedValues.sort(function (x, y) {
                            return x.sort - y.sort;
                        });
                    }
                    for (const v of sortedValues) {
                        rf.values.push({ id: v.id, name: v.name, parentId: v.parentId });
                    };
                }
                setReportFilters(reportFilters);
            }
            setFilterLoadIndex(filterLoadIndex + 1);
        }
        // eslint-disable-next-line
    }, [filterAttributes]);

    const initializeReportFilters = () => {
        for (const r of reportFilters) {
            let d = cubeAttributes.find(q => q.name === r.name);
            if (d) {
                r.msId = d.id;
                r.values = [];
                for (const v of d.elements) {
                    r.values.push({ id: v.key, name: v.value, parentId: v.key });
                }

                // set default value (need to add multiple select
                if (r.values.length > 0) {
                    r.value = r.values[0].id;
                }
            }

        }
        setReportFilters([...reportFilters]);
    }


    const updateFilterValues = (f: IMicroStrategyFilter) => {
        const t: IReportFilter = reportFilters.filter(q => q.msId === f.key)[0]
        t.value = f.value;
        if (f.supportMultiple) {
            t.valueName = 'Multiple Selections';
        }
        else {
            t.valueName = f.values.filter(q => q.id === f.value)[0].name;
        }
        setProcessingStep(ProcessingDossierFilters.UpdateFilters);
    }

    return (
        <>
            <Filters
                updateFilterValues={updateFilterValues}
                filters={filters}
                reportId={report.id}
            />
            <OESpinner message={message} oeStyle={SpinnerStyle.Small} />
            {processingStep === ProcessingDossierFilters.FatalError && (
                <OEMessage
                    className="report-errors"
                    hideDismissable={true}
                    message={error}
                    type={MessageType.Error}
                />
            )}

        </>
    );
};

export default DossierFilters;
