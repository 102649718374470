export enum MicroStrategyDossierFilterType {
    SingleSelect = 1,
    MultipleSelect,
    Slider
}

export interface IMicroStrategyDossierFilterSelection {
    name?: string;
    value?: string;
}

export const defaultMicroStrategyDossierFilterSelection: IMicroStrategyDossierFilterSelection = {
    value: ''
};

export interface IMicroStrategyDossierFilterInfo {
    key: string;
}

export const defaultMicroStrategyDossierFilterInfo: IMicroStrategyDossierFilterInfo = {
    key: ''
};

export interface IMicroStrategyDossierFilterSingle {
    filterInfo: IMicroStrategyDossierFilterInfo;
    holdSubmit?: boolean;
    selection: IMicroStrategyDossierFilterSelection;
}

export const defaultMicroStrategyDossierFilterSingle: IMicroStrategyDossierFilterSingle = {
    filterInfo: defaultMicroStrategyDossierFilterInfo, selection: defaultMicroStrategyDossierFilterSelection
};

export interface IMicroStrategyDossierFilterMultiple {
    filterInfo: IMicroStrategyDossierFilterInfo;
    holdSubmit?: boolean;
    selections: IMicroStrategyDossierFilterSelection[];
}

export const defaultMicroStrategyDossierFilterMultiple: IMicroStrategyDossierFilterMultiple = {
    filterInfo: defaultMicroStrategyDossierFilterInfo, selections: []
};


export const isSuppressionFilter = (n: string): boolean => {
    try {
        return n.toLowerCase() === 'suppressed' || n.toLowerCase() === 'suppression';
    }
    catch {
        return false;
    }
}

export interface IMicroStrategyDossierFilter {
    singleSelect?: IMicroStrategyDossierFilterSingle;
    multipleSelect?: IMicroStrategyDossierFilterMultiple;
    type: MicroStrategyDossierFilterType;
    start?: number;
    end?: number;
    name: string;
}