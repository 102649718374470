/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import OEButton from '../../core/components/form/OEButton';
import OEForm, { OEFormGroup, OEFormLabel } from '../../core/components/form/OEForm';
import OEInput, { OEInputType } from '../../core/components/form/OEInput';
import OEHeading from '../../core/components/general/OEHeading';
import { OECol } from '../../core/components/grid/OECol';
import { OERow } from '../../core/components/grid/OERow';
import OEMessage from '../../core/components/messaging/OEMessage';
import OENotification from '../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../core/components/messaging/entities/Notification';
import OETable from '../../core/components/table/OETable';
import { ColumnType, IColumn } from '../../core/components/table/entities/Column';
import { emptyGUID } from '../../core/utilities/String';
import TokenValidation from '../../report-microstrategy/components/report-common/TokenValidation';
import { ICubeAttribute, getDefaultCubeAttribute } from '../../report-microstrategy/entities/api/CubeResponse';
import { getMSAuthorizationToken } from '../../report-microstrategy/services/MicrostrategyService';
import { IReport, defaultReport } from '../../reporting/entities/Report';
import { ReportConfigurationType, getReportConfigurationValue } from '../../reporting/entities/ReportConfiguration';
import { useGetReport } from '../../reporting/services/ReportService';
import Cube from './Cube';

const MicrostrategyCubeDefinition: React.FunctionComponent = () => {
    const { service: reportService, setItemId: setGetReportId } = useGetReport();

    const [cubeAttributes, setCubeAttributes] = useState<ICubeAttribute[]>([]);
    const [cubeAttribute, setCubeAttribute] = useState<ICubeAttribute>(getDefaultCubeAttribute());

    const [validToken, setValidToken] = useState<boolean>(false);
    const [report, setReport] = useState<IReport>(defaultReport);

    const [error, setError] = useState<any>();
    const [result, setResult] = useState<string>('');
    const [reportId, setReportId] = useState<string>('fd66e5cb-2494-d09e-88e9-3a12b8ea2874');
    const [cubeId, setCubeId] = useState<string>('');
    const [tokenId, setTokenId] = useState<string>('');
    const [debugMessage, setDebugMessage] = useState<string>('');
    const [notification, setNotification] = useState<INotification>(defaultNotification);

    useEffect(() => {
        if (validToken) {
            setTokenId(getMSAuthorizationToken());
        }
        // eslint-disable-next-line
    }, [validToken]);

    useEffect(() => {
        if (reportService.result) {
            setReport(reportService.result.report);
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        if (!emptyGUID(report.id)) {
            setCubeId(getReportConfigurationValue(ReportConfigurationType.CubeID, report.configuration));
        }
        // eslint-disable-next-line
    }, [report]);

    const onGetReport = () => {
        setCubeAttributes([]);
        setCubeAttribute(getDefaultCubeAttribute());
        setResult('');
        setError('');
        setGetReportId(reportId);
    };

    const onRunCubeAttributeElements = (e: ICubeAttribute) => {
        setCubeAttribute(cubeAttributes.filter(q => q.id === e.id)[0]);
    };

    const columns: IColumn[] = [
        { id: 'name', name: 'Name', idNewLine: 'id',  sort: true, type: ColumnType.Link, onClick: onRunCubeAttributeElements },
    ];

    const attributeColumns: IColumn[] = [
        { id: 'value', idNewLine: 'rawValue', name: 'Value/Raw Value', sort: true, type: ColumnType.String, },
        { id: 'key', idNewLine: 'id', name: 'Key/Raw Id', sort: true, type: ColumnType.String, },
    ];

    const setCube = (c: ICubeAttribute[]) => {
        setCubeAttributes(c);
        if (c.length > 0) {
            setCubeAttribute(c[0]);
        }
    }

    return (
        <div className="container m-t-40">
            <TokenValidation validToken={validToken} setValidToken={setValidToken} />
            {!validToken && (
                <OEMessage hideDismissable={true} message="Token is not valid" />
            )}
            <div>
                <small className="text-success">{`${debugMessage}`}</small>
            </div>
            {validToken && (
                <>
                    <OENotification setNotification={setNotification} notification={notification} />
                    <OEHeading size={1} text="MicroStrategy Cube Definition" />
                    <OEForm>
                        <OERow>
                            <OECol sm={4}>
                                <OEFormGroup inline={true} className="m-r-10">
                                    <OEFormLabel>Report ID</OEFormLabel>
                                    <OEInput onChange={setReportId} type={OEInputType.Text} value={reportId} />
                                </OEFormGroup>
                            </OECol>
                            <OECol sm={4}>
                                <OEFormGroup inline={true} className="m-r-10">
                                    <OEFormLabel>Token ID</OEFormLabel>
                                    <OEInput disabled={true} type={OEInputType.Text} value={tokenId} />
                                </OEFormGroup>
                            </OECol>
                            <OECol sm={4}>
                                <OEFormGroup inline={true} className="m-r-10">
                                    <OEFormLabel>Cube ID</OEFormLabel>
                                    <OEInput disabled={true} type={OEInputType.Text} value={cubeId} />
                                </OEFormGroup>
                            </OECol>
                        </OERow>
                    </OEForm>

                    <OEButton className="m-b-40 m-t-20" onClick={onGetReport} text="Load Cube" />
                    <Cube
                        validToken={validToken}
                        setCube={setCube}
                        report={report}
                        setNotification={setNotification}
                        cubeId={cubeId}
                        setResult={setResult}
                        setError={setError}
                        setDebugMessage={setDebugMessage}
                    />
                    {report.title !== '' && (
                        <>
                            <h2>{report.title} </h2>

                            {cubeAttributes.length > 0 && (
                                <OERow>
                                    <OECol sm={4}>
                                        <h2>Cube</h2>
                                        <OETable
                                            data={cubeAttributes}
                                            columns={columns}
                                        />
                                    </OECol>
                                    <OECol sm={8}>
                                        <h2>{`Elements for ${cubeAttribute.name}`}</h2>
                                        <OETable
                                            data={cubeAttribute.elements}
                                            columns={attributeColumns}
                                            showPagination={true}
                                            noDataMessage="No Data Found, click name in Cube table to load elements"
                                            defaultPageSize={10}
                                        />
                                    </OECol>
                                </OERow>
                            )}
                            <OERow>
                                <OECol sm={6}>
                                    <h5>Response</h5>
                                    <OEInput type={OEInputType.LargeText} rows={2} value={result} disabled={true} />
                                </OECol>
                                <OECol sm={6}>
                                    <h5>Error</h5>
                                    <OEInput type={OEInputType.LargeText} rows={2} value={error} disabled={true} />
                                </OECol>
                            </OERow>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default MicrostrategyCubeDefinition;
