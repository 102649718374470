export enum ProgressMessages {
    Dashboard = 'Saving Dashboard Content',
    DcFileUpload = 'Saving File Upload',
    DefaultProgress = "Saving",
    Menu = 'Saving Menu',
    OrganizationType = 'Saving Organization Type',
    OrganizationUnit = 'Saving Organization Unit',
    Profile = 'Saving Profile',
    ProfileImage = 'Saving Profile Image',
    Report = 'Saving Report',
    ReportGroup = 'Saving Report Group',
    ReportPage = 'Saving Report Page',
    ReportServer = 'Saving Report Server',
    ReportType = 'Saving Report Type',
    Role = 'Saving Role',
    Route = 'Saving Route',
    User = 'Saving User',
    Artifact = 'Saving Artifact',
    DatasetConnection = 'Saving Dataset Connection',
}